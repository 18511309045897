import { ForbiddenError, subject } from '@casl/ability';
import classNames from 'classnames';
import merge from 'lodash/merge';
import uniqBy from 'lodash/uniqBy';
import * as React from 'react';
import { DefaultValues, useController, useFormContext } from 'react-hook-form';
import { MutationStatus, useQueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { z } from 'zod';

import {
  AdminUserRoleEnum,
  AgencyRepUserRoleEnum,
  Company,
  CompanyManagerUserRoleEnum,
  CompanyWithLocations,
  CreateUserBody,
  FileType,
  LocationManagerUserRoleEnum,
  PendingUserStatus,
  RegisteredUserStatus,
  RegularUserRoleEnum,
  ResourceUserRoleEnum,
  SalesRepUserRoleEnum,
  User,
  UserRole,
  UserUpdate,
} from '__generated-api__';
import api from 'api';
import { AuthStatus, useAbility, useAuth, useAuthenticatedUser } from 'auth';
import { Accordion } from 'components/Accordion';
import { Button } from 'components/Button';
import { Dropdown } from 'components/Dropdown';
import CheckboxField from 'components/Form/CheckboxField';
import Form, { FormProvider, useForm } from 'components/Form/Form';
import InputField from 'components/Form/InputField';
import SelectField from 'components/Form/SelectField';
import SubmitButton from 'components/Form/SubmitButton';
import UnsavedChangesPrompt from 'components/Form/UnsavedChangesPrompt';
import { Pagination } from 'components/Pagination';
import Icon from 'components/icon';
import { useFuse } from 'hooks/fuse';
import { useMutation } from 'hooks/query';
import { ProfileImageField } from 'my-account/components/ProfileImage';
import { RequestPasswordResetButton } from 'my-account/components/RequestPasswordResetButton';
import { SearchFilter } from 'my-account/components/SearchFilter';
import { SelectAgency } from 'my-account/components/SelectAgency';
import { SelectCompany } from 'my-account/components/SelectCompany';
import { SelectLocation } from 'my-account/components/SelectLocation';
import { useToast } from 'my-account/toast';
import { useErrorHandler } from 'my-account/utils/error-handler';
import { getObjectFromEntries, getObjectKeys } from 'my-account/utils/object';
import { caProvinces, usStates } from 'my-account/utils/states';
import { getUserName } from 'my-account/utils/user';
import { CompanyRelationSchema } from 'my-account/validations/company';

const RepresentationSchema = z.object({
  id: z.number().positive(),
  name: z.string().nullable(),
  is_selected: z.boolean(),
  locations: z.array(
    z.object({
      id: z.number(),
      is_selected: z.boolean(),
      name: z.string().nullable(),
      address: z.string().nullable(),
      city: z.string().nullable(),
      state: z.string().nullable(),
      zip_code: z.string().nullable(),
    })
  ),
});

const UserUpdateSchema = z
  .object({
    first_name: z.string().nonempty(),
    last_name: z.string().nonempty(),
    email: z.string().nonempty().email(),
    role: z.nativeEnum(UserRole),
    image: z
      .object({
        id: z.number().positive(),
        title: z.string(),
        thumbnail: z.string(),
      })
      .nullable()
      .optional(),
    permissions: z
      .object({
        can_view_company_orders: z.boolean().optional(),
        can_view_location_orders: z.boolean().optional(),
        // can_view_my_orders: z.boolean().optional(),
        can_view_pricing: z.boolean().optional(),
      })
      .optional(),
    agency: z
      .object({
        id: z.number().positive(),
        name: z.string().nullable(),
      })
      .optional(),
    company: z
      .object({
        id: z.number().positive(),
        name: z.string().nullable(),
      })
      .optional(),
    location: z
      .object({
        id: z.number().positive(),
        name: z.string().nullable(),
      })
      .optional(),
    rep_managed: z.boolean().optional(),
    phone: z.string().nullable().optional(),
    // represents: z
    //   .object({
    //     companies: z.array(z.object({ id: z.number() })),
    //     locations: z.array(z.object({ id: z.number() })),
    //   })
    //   .optional(),
    representations: z.array(RepresentationSchema).optional(),
  })
  .refine(
    (data) => {
      if (
        data.role === UserRole.User ||
        data.role === UserRole.LocationManager ||
        data.role === UserRole.CompanyManager
      ) {
        if (!data.company || !data.company.id) {
          return false;
        }
      }

      return true;
    },
    { message: 'Company is required.', path: ['company'] }
  )
  .refine(
    (data) => {
      if (data.role === UserRole.User || data.role === UserRole.LocationManager) {
        if (!data.location || !data.location.id) {
          return false;
        }
      }

      return true;
    },
    { message: 'Location is required.', path: ['location'] }
  );

const SelectedRoleGuard: React.FC<{ roles: UserRole[] }> = ({ roles, children }) => {
  const { watch } = useFormContext<z.infer<typeof UserUpdateSchema>>();
  const { role } = watch();

  if (!roles.includes(role)) {
    return null;
  }

  return <>{children}</>;
};

const WatchSelectedCompany: React.VFC<{
  children: (company?: z.infer<typeof UserUpdateSchema>['company']) => React.ReactNode;
}> = ({ children }) => {
  const { watch } = useFormContext<z.infer<typeof UserUpdateSchema>>();
  const { company } = watch();

  return <>{children(company)}</>;
};

const addRepresentativeSchema = z.object({
  companies: z.array(CompanyRelationSchema),
});

const AddRepresentative: React.VFC<{
  companiesIds: string[];
  onAdd: (companies: Company[]) => void;
  onClose: () => void;
}> = ({ companiesIds, onAdd, onClose }) => {
  const auth = useAuth();
  const isAdmin = auth.status === AuthStatus.LoggedIn && auth.currentUser.role === AdminUserRoleEnum.Admin;
  const [isAdding, setIsAdding] = React.useState(false);
  const { handleError, clearErrors } = useErrorHandler();

  const { context, formProps } = useForm({
    schema: addRepresentativeSchema,
    initialValues: {
      companies: undefined,
    },
    async onSubmit(values, ctx, event) {
      event?.stopPropagation();

      if (isAdding) {
        return;
      }

      clearErrors();

      setIsAdding(true);

      try {
        const companies = await Promise.all(
          values.companies.map(async (company) => (await api.company.getCompany({ id: company.id })).data)
        );
        onAdd(companies);
        onClose();
      } catch (e) {
        handleError(e);
      }

      return Promise.resolve();
    },
  });

  if (!isAdmin) {
    return null;
  }

  return (
    <FormProvider {...context}>
      <div className="c-fetch-card c-fetch-card--colappsable">
        <div className="c-fetch-card__inner">
          <div className="c-fetch-card__input">
            <SelectCompany
              name="companies"
              elStyle="fill"
              placeholder="Company"
              isMulti
              selectProps={{
                filterOption: (option) => {
                  return companiesIds.indexOf(option.value) === -1;
                },
                onKeyDown: (event) => {
                  if (event.key === 'Enter') {
                    formProps.onSubmit();
                  }
                },
              }}
            />
          </div>

          <div>
            <Button
              onClick={(event) => {
                event.preventDefault();
                formProps.onSubmit();
              }}
              rightIcon="arrow"
              isLoading={isAdding}
            >
              Add
            </Button>
          </div>
        </div>
        <div className="c-fetch-card__close">
          <button
            type="button"
            onClick={(event) => {
              event.preventDefault();
              onClose();
            }}
          >
            <Icon name="close-thin" className="o-svg-icon o-svg-right" />
          </button>
        </div>
      </div>
    </FormProvider>
  );
};

const salesRepsCompanyLocationsSorts = {
  'most relevant': 'Most relevant',
  latest: 'Latest',
  alphabetical: 'Alphabetical',
} as const;

const SalesRepsCompanyLocations: React.VFC<{
  companyIndex: number;
  locations: z.infer<typeof RepresentationSchema>['locations'];
}> = ({ companyIndex, locations }) => {
  const initiallySelectedLocations = React.useMemo(
    () => locations.filter((location) => location.is_selected).map((location) => location.id),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const context = useFormContext<z.infer<typeof UserUpdateSchema>>();
  const selectedLocations = locations.filter((location) => location.is_selected);
  const [current_page, setCurrentPage] = React.useState(1);
  const [stateFilter, setStateFilter] = React.useState<
    // eslint-disable-next-line
    '' | (typeof usStates)[keyof typeof usStates] | (typeof caProvinces)[keyof typeof caProvinces]
  >('');
  const [sort, setSort] = React.useState<keyof typeof salesRepsCompanyLocationsSorts>('most relevant');
  const [dir, setDir] = React.useState<'asc' | 'desc'>('asc');
  const ITEMS_PER_PAGE = 10;
  const filteredLocations = locations
    .map((location, index) => [location, index] as const)
    .filter(([location]) => {
      if (stateFilter && location.state !== stateFilter) {
        return false;
      }

      return true;
    });
  const data = filteredLocations
    .slice()
    .sort(([locationA], [locationB]) => {
      if (sort === 'most relevant') {
        const wasLocationASelected = initiallySelectedLocations.includes(locationA.id) ? 1 : 0;
        const wasLocationBSelected = initiallySelectedLocations.includes(locationB.id) ? 1 : 0;

        return dir === 'asc'
          ? wasLocationBSelected - wasLocationASelected
          : wasLocationASelected - wasLocationBSelected;
      }

      if (sort === 'latest') {
        return dir === 'asc' ? locationA.id - locationB.id : locationB.id - locationA.id;
      }

      if (sort === 'alphabetical') {
        const comparison = (locationA.name ?? '').localeCompare(locationB.name ?? '', 'en-US', {
          ignorePunctuation: true,
        });
        return dir === 'asc' ? comparison : comparison > 0 ? -1 : comparison < 0 ? 1 : 0;
      }

      return 0;
    })
    .slice((current_page - 1) * ITEMS_PER_PAGE, current_page * ITEMS_PER_PAGE);
  const last_page = Math.ceil(filteredLocations.length / ITEMS_PER_PAGE);
  const availableStateKeys = locations
    .map((location) => location.state ?? undefined)
    .filter((state): state is string => typeof state !== 'undefined');

  const availableStates = [
    ...getObjectKeys(usStates).map((label) => [usStates[label], label] as const),
    ...getObjectKeys(caProvinces).map((label) => [caProvinces[label], label] as const),
  ]
    .filter((state) => availableStateKeys.includes(state[0]))
    .reduce((res, item) => {
      return { ...res, [item[0]]: item[1] };
      // eslint-disable-next-line
    }, {} as Record<(typeof usStates)[keyof typeof usStates] | (typeof caProvinces)[keyof typeof caProvinces], keyof typeof usStates | keyof typeof caProvinces>);

  return (
    <>
      <div className="o-row">
        <div className="o-col-12">
          <div className="c-listing c-listing--location">
            <div className="c-listing__header u-mb-spacer">
              <div className="c-listing__header-views">
                <div className="c-listing__header-controls">
                  <Dropdown
                    label="Selected: "
                    description="Select"
                    dropdownPosition="left"
                    options={{
                      '': selectedLocations.length || 'none',
                      all: 'All',
                      none: 'None',
                      ...(filteredLocations.length > 0 && filteredLocations.length !== locations.length
                        ? { filtered: 'Filtered results' }
                        : {}),
                    }}
                    onChange={(select) => {
                      if (select === 'all') {
                        context.setValue(
                          `representations.${companyIndex}.locations` as 'representations.0.locations',
                          locations.map((l) => ({ ...l, is_selected: true }))
                        );
                      }

                      if (select === 'none') {
                        context.setValue(
                          `representations.${companyIndex}.locations` as 'representations.0.locations',
                          locations.map((l) => ({ ...l, is_selected: false }))
                        );
                      }

                      if (select === 'filtered') {
                        const filteredLocationsIds = filteredLocations.map((item) => item[0].id);
                        context.setValue(
                          `representations.${companyIndex}.locations` as 'representations.0.locations',
                          locations.map((l) => ({
                            ...l,
                            is_selected: l.is_selected || filteredLocationsIds.includes(l.id),
                          }))
                        );
                      }
                    }}
                    value=""
                    hideEmptyKey
                  />

                  <Dropdown
                    label="Filter by:"
                    description="Filters"
                    options={
                      {
                        '': !stateFilter
                          ? 'state/province'
                          : ({ isToggle }) => (
                              <>
                                Clear Filter{' '}
                                <span>
                                  <Icon name="close-thin" />
                                </span>
                              </>
                            ),
                        ...availableStates,
                      } as const
                    }
                    onChange={(select) => {
                      if (stateFilter && (select === '' || select === stateFilter)) {
                        setStateFilter('');
                      } else {
                        setStateFilter(select);
                      }
                      setCurrentPage(1);
                    }}
                    value={stateFilter}
                    hideEmptyKey={!stateFilter}
                  />

                  <Dropdown
                    label="Sort:"
                    description="Sort items by"
                    options={getObjectFromEntries(
                      getObjectKeys(salesRepsCompanyLocationsSorts).map((key) => {
                        return [
                          key,
                          ({ isToggle }: { isToggle: boolean }) => (
                            <>
                              {isToggle
                                ? salesRepsCompanyLocationsSorts[key].toLowerCase()
                                : salesRepsCompanyLocationsSorts[key]}
                              {!isToggle && sort === key && (
                                <span>
                                  {dir === 'asc' ? (
                                    <svg width="17" height="13" xmlns="http://www.w3.org/2000/svg">
                                      <g fill="none" fillRule="evenodd">
                                        <path
                                          d="M12.186.836c.057.025.11.06.154.104l4.32 4.32a.481.481 0 01-.68.679l-3.5-3.5v9.88a.48.48 0 01-.96 0V2.44l-3.5 3.5a.48.48 0 01-.68-.68L11.66.943a.488.488 0 01.527-.106z"
                                          fill="#FFF"
                                        />
                                        <path
                                          d="M3.876 12.175a.326.326 0 01-.102-.068l-2.88-2.88a.32.32 0 01.452-.453l2.334 2.334V4.52a.32.32 0 01.64 0v6.588l2.334-2.334a.32.32 0 01.452.452l-2.878 2.879a.325.325 0 01-.351.07z"
                                          fill="#000"
                                          opacity=".3"
                                        />
                                      </g>
                                    </svg>
                                  ) : (
                                    <svg width="17" height="13" xmlns="http://www.w3.org/2000/svg">
                                      <g fill="none" fillRule="evenodd">
                                        <path
                                          d="M5.414 12.763a.488.488 0 01-.154-.103L.94 8.34a.481.481 0 01.68-.68l3.5 3.501V1.28a.48.48 0 01.96 0v9.881l3.5-3.5a.48.48 0 01.68.679L5.94 12.657a.488.488 0 01-.527.106z"
                                          fill="#FFF"
                                        />
                                        <path
                                          d="M13.724 4.424c.038.016.073.04.102.069l2.88 2.88a.32.32 0 01-.452.453L13.92 5.492v6.588a.32.32 0 01-.64 0V5.492l-2.334 2.334a.32.32 0 01-.452-.453l2.878-2.878a.325.325 0 01.351-.07z"
                                          fill="#000"
                                          opacity=".3"
                                        />
                                      </g>
                                    </svg>
                                  )}
                                </span>
                              )}
                            </>
                          ),
                        ] as const;
                      })
                    )}
                    onChange={(select) => {
                      if (select === sort) {
                        setDir((val) => {
                          return val === 'asc' ? 'desc' : 'asc';
                        });
                      } else {
                        setSort(select);
                      }
                      setCurrentPage(1);
                    }}
                    value={sort}
                    className="u-ml-auto@lg"
                  />
                </div>
              </div>
            </div>
            {data.map(([location, location_index]) => (
              <div key={location.id} className={classNames('c-listing__row', { 'is-selected': location.is_selected })}>
                <div className="c-listing__content">
                  <div className="c-data-card c-data-card--location">
                    <div className="c-data-card__column c-data-card__column--first">
                      <CheckboxField
                        label={location.name ?? String(location.id)}
                        name={`representations.${companyIndex}.locations.${location_index}.is_selected`}
                        checkboxStyle="checkbox"
                        className="c-form-element--style-fill c-form-element--inline"
                      />
                    </div>
                    <div className="c-data-card__column c-data-card__column--last">
                      <span className="u-text-neutral-600">
                        {location.state} {location.zip_code}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="o-row u-mt-spacer-base-large">
        <div className="o-col-12">
          <Pagination current_page={current_page} last_page={last_page} navigate={(page) => setCurrentPage(page)} />
        </div>
      </div>
    </>
  );
};

const RepresentationsField = () => {
  const [searchValue, setSearchValue] = React.useState<string | undefined>(undefined);
  const companiesField = useController<z.infer<typeof UserUpdateSchema>, 'representations'>({
    name: 'representations',
  });
  const [current_page, setCurrentPage] = React.useState(1);
  const ITEMS_PER_PAGE = 6;
  const allData = companiesField.field.value ?? [];
  const { results, search } = useFuse(allData, {
    findAllMatches: true,
    keys: [
      { name: 'bill_to_id', weight: 1 },
      { name: 'name', weight: 3 },
    ],
  });
  const data = results.slice((current_page - 1) * ITEMS_PER_PAGE, current_page * ITEMS_PER_PAGE);
  const last_page = Math.ceil(results.length / ITEMS_PER_PAGE);

  React.useEffect(() => {
    search(searchValue || '');
  }, [search, searchValue]);

  const [isAddRepresentativeOpen, setIsAddRepresentativeOpen] = React.useState(
    (companiesField.field.value ?? []).length === 0
  );

  const onChange = (value: z.infer<typeof RepresentationSchema>[]) => {
    companiesField.field.onChange(value);
  };

  const addCompanies = (companies: z.infer<typeof RepresentationSchema>[]) => {
    onChange(uniqBy([...(companiesField.field.value ?? []), ...companies], 'id'));
  };

  const removeCompanyWithId = (id: number) => {
    if (companiesField.field.value) {
      onChange(companiesField.field.value.filter((sales_rep) => sales_rep.id !== id));
    }
  };

  return (
    <div className="o-row">
      <div className="o-col-3@md">
        <p className="u-text-xs u-uppercase u-mb-spacer-base-small">Accounts</p>
        <SearchFilter
          placeholder="Search by name"
          defaultValue={searchValue || ''}
          navigateSearch={setSearchValue}
          navigatePagination={(page) => setCurrentPage(page)}
        />
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          href="#"
          className="c-link-cta-basic u-mt-spacer-base-small u-mb-spacer-base u-text-xs"
          onClick={(event) => {
            event.preventDefault();
            setIsAddRepresentativeOpen(true);
          }}
        >
          <span>Add new account</span>
          <Icon name="add" className="o-svg-icon u-text-neutral-300" />
        </a>
      </div>

      <div className="o-col-9@md">
        <div className="o-row o-row--small-gutters">
          <div className="o-col-12">
            {data.map(({ item: company, refIndex: companyIndex }) => (
              <Accordion
                key={company.id}
                title={company.name}
                subtitle={
                  company.is_selected && typeof company.locations.find((l) => l.is_selected) !== 'undefined'
                    ? 'Company and locations representative'
                    : company.is_selected
                    ? 'Company representative'
                    : typeof company.locations.find((l) => l.is_selected) !== 'undefined'
                    ? 'Location representative'
                    : undefined
                }
                subtitlePosition="below-title"
                type="card"
                toggleActions={
                  <div className="u-hidden u-block@sm">
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      href="#"
                      className="c-link-cta-basic c-link-cta--small"
                      onClick={(event) => {
                        event.preventDefault();
                        removeCompanyWithId(company.id);
                      }}
                    >
                      <span>Remove account</span>
                    </a>
                  </div>
                }
              >
                <div className="o-row">
                  <div className="o-col-12">
                    <CheckboxField
                      checkboxStyle="toggle"
                      name={`representations.${companyIndex}.is_selected`}
                      label="User is a representative for this company"
                    />
                  </div>
                  {company.locations.length > 0 && (
                    <div className="o-col-12">
                      <hr className="u-my-spacer-base" />
                      <SalesRepsCompanyLocations companyIndex={companyIndex} locations={company.locations} />
                    </div>
                  )}
                </div>
              </Accordion>
            ))}
            {isAddRepresentativeOpen && (
              <AddRepresentative
                companiesIds={(companiesField.field.value ?? []).map((company) => String(company.id))}
                onAdd={(companies) => {
                  addCompanies(
                    companies.map((company) => ({
                      id: company.id,
                      name: company.name,
                      is_selected: true,
                      locations: company.addresses.flatMap((address) =>
                        (address.locations ?? []).map((location) => ({
                          id: location.id,
                          is_selected: false,
                          name: location.name,
                          address: location.address,
                          city: location.city,
                          state: location.state,
                          zip_code: location.zip_code,
                        }))
                      ),
                    }))
                  );
                }}
                onClose={() => {
                  setIsAddRepresentativeOpen(false);
                }}
              />
            )}

            <Pagination current_page={current_page} last_page={last_page} navigate={(page) => setCurrentPage(page)} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const EditUserForm: React.VFC<{ user?: User; representations?: CompanyWithLocations[] }> = ({
  user,
  representations = [],
}) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const history = useHistory();
  const routerLocation = useLocation();
  const authUser = useAuthenticatedUser();
  const ability = useAbility();
  const [createUser] = useMutation(api.user.createUser);
  const [updateUser] = useMutation(api.user.updateUser);
  const [deleteUser] = useMutation(api.user.deleteUser);
  const [getRepresentations] = useMutation(api.user.represents);
  const [uploadImageStatus, setUploadImageStatus] = React.useState<MutationStatus>('idle');
  const getUserInitialValues = React.useCallback(
    (
      user: User | undefined,
      representations: CompanyWithLocations[] | undefined
    ): DefaultValues<z.infer<typeof UserUpdateSchema>> & { _readOnly?: Record<string, any> } => {
      const defaultAgency = authUser.role === AgencyRepUserRoleEnum.AgencyRep ? authUser.agency : undefined;
      const defaultCompany =
        authUser.role !== AdminUserRoleEnum.Admin &&
        authUser.role !== SalesRepUserRoleEnum.SalesRep &&
        authUser.role !== AgencyRepUserRoleEnum.AgencyRep &&
        authUser.role !== ResourceUserRoleEnum.ResourceAccess
          ? authUser.company
          : undefined;
      const defaultLocation =
        authUser.role !== AdminUserRoleEnum.Admin &&
        authUser.role !== SalesRepUserRoleEnum.SalesRep &&
        authUser.role !== AgencyRepUserRoleEnum.AgencyRep &&
        authUser.role !== CompanyManagerUserRoleEnum.CompanyManager &&
        authUser.role !== ResourceUserRoleEnum.ResourceAccess
          ? authUser.location
          : undefined;

      if (typeof user === 'undefined') {
        let role: UserRole | undefined;

        if (authUser.role === AdminUserRoleEnum.Admin && routerLocation.search.length) {
          const search = new URLSearchParams(routerLocation.search);

          if (search.get('role') === UserRole.SalesRep) {
            role = UserRole.SalesRep;
          }
        }

        return {
          agency: defaultAgency || undefined,
          // company_id: defaultCompany?.id,
          company: defaultCompany || undefined,
          // location_id: defaultLocation?.id,
          location: defaultLocation || undefined,
          role,
          _readOnly: {
            company_name: defaultCompany?.name,
            location_name: defaultLocation?.name,
          },
        };
      }
      const agency = user.role === AgencyRepUserRoleEnum.AgencyRep ? user.agency || undefined : undefined;
      const company =
        user.role !== AdminUserRoleEnum.Admin &&
        user.role !== SalesRepUserRoleEnum.SalesRep &&
        user.role !== AgencyRepUserRoleEnum.AgencyRep &&
        user.role !== ResourceUserRoleEnum.ResourceAccess
          ? user.company || undefined
          : undefined;
      const location =
        user.role !== AdminUserRoleEnum.Admin &&
        user.role !== CompanyManagerUserRoleEnum.CompanyManager &&
        user.role !== AgencyRepUserRoleEnum.AgencyRep &&
        user.role !== SalesRepUserRoleEnum.SalesRep &&
        user.role !== ResourceUserRoleEnum.ResourceAccess
          ? user.location || undefined
          : undefined;

      return {
        first_name: user.first_name ?? '',
        last_name: user.last_name ?? '',
        email: user.email,
        role: user.role as unknown as UserRole,
        image: user.image
          ? {
              id: user.image.id,
              title: user.image.title,
              thumbnail: user.image.thumbnail,
            }
          : null,
        agency,
        company,
        location,
        permissions: user.permissions,
        rep_managed:
          user.role !== AdminUserRoleEnum.Admin &&
          user.role !== SalesRepUserRoleEnum.SalesRep &&
          user.role !== AgencyRepUserRoleEnum.AgencyRep
            ? user.rep_managed
            : undefined,
        phone:
          user.role === SalesRepUserRoleEnum.SalesRep || user.role === AgencyRepUserRoleEnum.AgencyRep
            ? user.phone
            : undefined,
        _readOnly: {
          company_name: company?.name,
          location_name: location?.name,
        },
        representations:
          (user.role === SalesRepUserRoleEnum.SalesRep || user.role === AgencyRepUserRoleEnum.AgencyRep) &&
          representations
            ? representations.map((company) => ({
                ...company,
                is_selected: typeof user.represents.companies.find((c) => c.id === company.id) !== 'undefined',
                locations: company.locations.map((location) => ({
                  ...location,
                  is_selected: typeof user.represents.locations.find((l) => l.id === location.id) !== 'undefined',
                })),
              }))
            : [],
      };
    },
    [authUser, routerLocation.search]
  );

  const isAdmin = authUser.role === AdminUserRoleEnum.Admin;
  const canSubmit = user ? ability.can('update', subject('User', user)) : ability.can('create', 'User');
  const canRemove = user ? ability.can('delete', subject('User', user)) : false;
  const canSetAnyCompany = ability.can('create', subject('User', { company_id: 0, role: RegularUserRoleEnum.User }));
  const canSetAnyLocation = ability.can(
    'create',
    subject('User', { company_id: authUser.company_id, location_id: 0, role: RegularUserRoleEnum.User })
  );

  return (
    <Form
      schema={UserUpdateSchema}
      onSubmit={async ({ representations, ...values }, ctx) => {
        ForbiddenError.from(ability).throwUnlessCan(
          user ? 'update' : 'create',
          subject(
            'User',
            merge({}, user, values, {
              agency_id: values.agency?.id,
              company_id: values.company?.id,
              location_id: values.location?.id,
            })
          )
        );

        const data: CreateUserBody | UserUpdate = {
          ...values,
          image: values.image ?? null,
          represents: {
            companies: (representations ?? []).filter((company) => company.is_selected).map(({ id }) => ({ id })),
            locations: (representations ?? [])
              .flatMap((company) => company.locations)
              .filter((location) => location.is_selected)
              .map(({ id }) => ({ id })),
          },
        };

        if (typeof user === 'undefined') {
          const res = await createUser([{ createUserBody: data }]);
          let newRepresentations: CompanyWithLocations[] = [];

          if (res.data.role === SalesRepUserRoleEnum.SalesRep || res.data.role === AgencyRepUserRoleEnum.AgencyRep) {
            newRepresentations = (await getRepresentations([{ userId: res.data.id }])).data;
          }

          ctx.reset(getUserInitialValues(res.data, newRepresentations));
          history.push(`/users/${res.data.id}`);

          toast.notify({
            type: 'success',
            title: 'Success',
            message: "You've successfully created a new User.",
          });
        } else {
          const res = await updateUser([
            {
              userId: user.id,
              userUpdate: {
                ...data,
                status: user.status === PendingUserStatus.Pending ? RegisteredUserStatus.Active : undefined,
              },
            },
          ]);
          let newRepresentations: CompanyWithLocations[] = [];

          if (res.data.role === SalesRepUserRoleEnum.SalesRep || res.data.role === AgencyRepUserRoleEnum.AgencyRep) {
            newRepresentations = (await getRepresentations([{ userId: res.data.id }])).data;
          }
          await Promise.all([
            queryClient
              .cancelQueries(api.user.listUsers.getQueryKey()[0])
              .then(() => queryClient.invalidateQueries(api.user.listUsers.getQueryKey()[0])),
            queryClient
              .cancelQueries(api.user.getUser.getQueryKey({ userId: user.id })[0])
              .then(() => queryClient.invalidateQueries(api.user.getUser.getQueryKey({ userId: user.id })[0])),
            queryClient
              .cancelQueries(api.user.represents.getQueryKey({ userId: user.id })[0])
              .then(() => queryClient.invalidateQueries(api.user.represents.getQueryKey({ userId: user.id })[0])),
          ]);
          ctx.reset(getUserInitialValues(res.data, newRepresentations));

          toast.notify({
            type: 'success',
            title: 'Success',
            message: "You've successfully updated the User.",
          });
        }
      }}
      initialValues={getUserInitialValues(user, representations)}
      className="u-mb-0"
      hasFloatingLabels
    >
      <UnsavedChangesPrompt />
      <section className="c-block c-block--spacing-t-extra-small c-block--spacing-b c-block--spacing-b-large@md">
        <div className="o-container-fluid">
          <div className="o-row">
            <div className="o-col-3@md">
              <p className="u-text-xs u-uppercase u-mb-spacer-base-small">General info</p>
              <p className="c-note">
                Enter the user’s name and email address, then click Save Changes when you’re done.
              </p>
            </div>

            <div className="o-col-9@md o-col-6@lg">
              <div className="o-row o-row--small-gutters">
                <div className="o-col-6@sm">
                  <InputField
                    name="first_name"
                    label="First Name"
                    elStyle="fill"
                    inputProps={{ autoComplete: 'off' }}
                  />
                </div>
                <div className="o-col-6@sm">
                  <InputField name="last_name" label="Last Name" elStyle="fill" inputProps={{ autoComplete: 'off' }} />
                </div>
                <div className="o-col-12">
                  <InputField
                    name="email"
                    label="Email address"
                    type="email"
                    elStyle="fill"
                    inputProps={{ autoComplete: 'off' }}
                  />
                </div>
              </div>
            </div>

            <div className="o-col-9 o-col-5@sm o-col-3@lg o-offset-3@md u-ml-0@lg">
              <ProfileImageField
                name="image"
                type={FileType.Avatar}
                onUploadStatusChange={(status) => {
                  setUploadImageStatus(status);
                }}
              />
            </div>
          </div>

          <div className="o-row">
            <div className="o-col-12">
              <hr className="u-mt-spacer-base u-mt-spacer-base-large@sm u-mb-spacer-base-large u-mb-spacer-section-small@sm" />
            </div>
          </div>

          <div className="o-row">
            <div className="o-col-3@md">
              <p className="u-text-xs u-uppercase u-mb-spacer-base-small">Permissions</p>
              <p className="c-note">
                Enter the user’s role, and related company, location, and permissions, then click Save Changes when
                you’re done.
              </p>
            </div>

            <div className="o-col-9@md o-col-6@lg">
              <div className="o-row o-row--small-gutters">
                <div className="o-col-12">
                  <SelectField name="role" label="User Role" elStyle="fill" selectProps={{ autoComplete: 'off' }}>
                    {isAdmin && <option value={UserRole.Admin}>Administrator</option>}
                    {isAdmin && <option value={UserRole.SalesRep}>Sales Representative</option>}
                    {isAdmin && <option value={UserRole.AgencyRep}>Agency Representative</option>}
                    {isAdmin && <option value={UserRole.CompanyManager}>Company Manager</option>}
                    <option value={UserRole.LocationManager}>Location Manager</option>
                    <option value={UserRole.User}>User</option>
                    <option value={UserRole.ResourceAccess}>Resource Access</option>
                  </SelectField>
                </div>
                <SelectedRoleGuard roles={[UserRole.SalesRep, UserRole.AgencyRep]}>
                  <div className="o-col-12">
                    <SelectAgency elStyle="fill" name="agency" label="Agency" selectProps={{ autoComplete: 'off' }} />
                    <InputField
                      type="text"
                      name="phone"
                      label="Phone Number"
                      elStyle="fill"
                      inputProps={{ autoComplete: 'off' }}
                    />
                  </div>
                </SelectedRoleGuard>
                <SelectedRoleGuard
                  roles={[UserRole.CompanyManager, UserRole.LocationManager, UserRole.User, UserRole.ResourceAccess]}
                >
                  <div className="o-col-12">
                    {canSetAnyCompany ? (
                      <SelectCompany
                        elStyle="fill"
                        name="company"
                        label="Company"
                        selectProps={{ autoComplete: 'off' }}
                      />
                    ) : (
                      <InputField
                        type="text"
                        name="_readOnly.company_name"
                        label="Company"
                        elStyle="fill"
                        disabled
                        inputProps={{ readOnly: true }}
                      />
                    )}
                  </div>
                </SelectedRoleGuard>
                <SelectedRoleGuard roles={[UserRole.LocationManager, UserRole.User, UserRole.ResourceAccess]}>
                  <WatchSelectedCompany>
                    {(company) => {
                      if (!company || !company.id) {
                        return null;
                      }

                      return (
                        <div className="o-col-12">
                          {canSetAnyLocation ? (
                            <SelectLocation
                              elStyle="fill"
                              name="location"
                              label="Location"
                              queryParams={{ companyId: company.id }}
                              selectProps={{ autoComplete: 'off' }}
                            />
                          ) : (
                            <InputField
                              type="text"
                              name="_readOnly.location_name"
                              label="Location"
                              elStyle="fill"
                              disabled
                              inputProps={{ readOnly: true }}
                            />
                          )}
                        </div>
                      );
                    }}
                  </WatchSelectedCompany>
                  <div className="o-col-12 u-mb-spacer-base u-mb-0@lg">
                    <CheckboxField
                      checkboxStyle="checkbox"
                      name="permissions[can_view_company_orders]"
                      label="Can view company orders?"
                    />
                    <CheckboxField
                      checkboxStyle="checkbox"
                      name="permissions[can_view_location_orders]"
                      label="Can view location orders?"
                    />
                    {/* <CheckboxField checkboxStyle="checkbox" name="permissions[can_view_my_orders]" label="Can view my orders?" /> */}
                    <CheckboxField
                      checkboxStyle="checkbox"
                      name="permissions[can_view_pricing]"
                      label="Can view pricing?"
                    />
                  </div>
                </SelectedRoleGuard>
                {isAdmin && (
                  <SelectedRoleGuard roles={[UserRole.CompanyManager, UserRole.LocationManager, UserRole.User]}>
                    <div className="o-col-12 u-mb-spacer-base u-mb-0@lg">
                      <CheckboxField
                        checkboxStyle="checkbox"
                        name="rep_managed"
                        label="Representative Agency Managed Account"
                      />
                    </div>
                  </SelectedRoleGuard>
                )}
              </div>
            </div>

            <div className="o-col-3@lg o-offset-3@md u-ml-0@lg">
              <div className="c-info u-mb-spacer-base">
                <div className="c-info__header">
                  <div className="c-info__icon u-text-secondary">
                    <Icon name="info" className="o-svg-icon" />
                  </div>
                  <p>User Role</p>
                </div>
                <div className="c-info__body">
                  <div className="c-info__content">
                    <p>
                      Use the drop-down list to select the user’s role within their company at that specific location.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="o-row">
            <div className="o-col-12">
              <hr className="u-mt-spacer-base u-mt-spacer-base-large@sm u-mb-spacer-base-large u-mb-spacer-section-small@sm" />
            </div>
          </div>

          <SelectedRoleGuard roles={[UserRole.SalesRep, UserRole.AgencyRep]}>
            <RepresentationsField />
          </SelectedRoleGuard>

          <div className="o-row">
            <div className="o-col-12">
              <hr className="u-mt-spacer-base u-mt-spacer-base-large@sm u-mb-spacer-base-large u-mb-spacer-section-small@sm" />
            </div>
          </div>

          {typeof user !== 'undefined' && user.status !== PendingUserStatus.Pending && (
            <>
              <div className="o-row">
                <div className="o-col-3@md">
                  <p className="u-text-xs u-uppercase u-mb-spacer-base-small">Password</p>
                  <p className="c-note">
                    Your password must be at least 8 characters long, contain at least one number, one uppercase or
                    lowercase letter, and at least one special character.
                  </p>
                </div>

                <div className="o-col-9@md">
                  <RequestPasswordResetButton email={user.email} />
                </div>
              </div>

              <div className="o-row">
                <div className="o-col-12">
                  <hr className="u-mt-spacer-base u-mt-spacer-base-large@sm u-mb-spacer-base-large u-mb-spacer-section-small@sm" />
                </div>
              </div>
            </>
          )}

          <div className="o-row u-items-center">
            <div className="o-col-4@md o-offset-3@md">
              <div className="c-form-footer">
                <SubmitButton
                  variant="secondary"
                  isFull
                  isLoading={uploadImageStatus === 'loading'}
                  disabled={!canSubmit}
                >
                  {typeof user === 'undefined'
                    ? 'Add new user'
                    : user.status === PendingUserStatus.Pending
                    ? 'Activate user & send welcome email'
                    : 'Save changes'}
                </SubmitButton>
              </div>
            </div>

            {canRemove && user && (
              <div className="o-col-5@md u-text-right@md u-pl-0@md">
                {(user.role === CompanyManagerUserRoleEnum.CompanyManager ||
                  user.role === LocationManagerUserRoleEnum.LocationManager ||
                  user.role === RegularUserRoleEnum.User) &&
                user.company &&
                !user.company.status ? (
                  <p className="c-note u-mb-0 u-text-sm">
                    This user cannot be activated due to being associated with an inactive{' '}
                    <Link to={`/companies/${user.company.id}`}>Company</Link>
                  </p>
                ) : (
                  <>
                    {(user.status === RegisteredUserStatus.Active || user.status === PendingUserStatus.Pending) && (
                      /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
                      <a
                        href="#"
                        className="c-link-cta-basic c-link-cta--small u-mt-spacer-base u-mt-0@md u-mb-0"
                        onClick={async (event) => {
                          event.preventDefault();

                          const userName = getUserName(user).name;

                          if (window.confirm(`Are you sure that you really want to archive "${userName}" user?`)) {
                            await deleteUser([{ userId: user.id }]);
                            await Promise.all([
                              queryClient
                                .cancelQueries(api.user.listUsers.getQueryKey()[0])
                                .then(() => queryClient.invalidateQueries(api.user.listUsers.getQueryKey()[0])),
                              queryClient
                                .cancelQueries(api.user.getUser.getQueryKey({ userId: user.id })[0])
                                .then(() =>
                                  queryClient.invalidateQueries(api.user.getUser.getQueryKey({ userId: user.id })[0])
                                ),
                            ]);
                            history.push('/users');
                            toast.notify({
                              type: 'success',
                              title: 'Success',
                              message: `The "${userName}" user was archived successfully.`,
                            });
                          }
                        }}
                      >
                        <Icon name="trash" className="o-svg-icon o-svg-larger" />
                        <span>Archive user account</span>
                      </a>
                    )}

                    {user.status === RegisteredUserStatus.Inactive && (
                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                      <a
                        href="#"
                        className="c-link-cta-basic c-link-cta--small u-mt-spacer-base u-mt-0@md u-mb-0"
                        onClick={async (event) => {
                          event.preventDefault();

                          const userName = getUserName(user).name;

                          if (window.confirm(`Are you sure that you really want to activate "${userName}" user?`)) {
                            await updateUser([
                              {
                                userId: user.id,
                                userUpdate: { status: RegisteredUserStatus.Active },
                              },
                            ]);
                            await Promise.all([
                              queryClient
                                .cancelQueries(api.user.listUsers.getQueryKey()[0])
                                .then(() => queryClient.invalidateQueries(api.user.listUsers.getQueryKey()[0])),
                              queryClient
                                .cancelQueries(api.user.getUser.getQueryKey({ userId: user.id })[0])
                                .then(() =>
                                  queryClient.invalidateQueries(api.user.getUser.getQueryKey({ userId: user.id })[0])
                                ),
                            ]);
                            toast.notify({
                              type: 'success',
                              title: 'Success',
                              message: `The "${userName}" user was activated successfully.`,
                            });
                          }
                        }}
                      >
                        <Icon name="export" className="o-svg-icon o-svg-larger" />
                        <span>Activate user account</span>
                      </a>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </section>
    </Form>
  );
};
