import { subject } from '@casl/ability';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { z } from 'zod';

import {
  AdminUserRoleEnum,
  AgencyRepUserRoleEnum,
  CompanyManagerUserRoleEnum,
  LocationManagerUserRoleEnum,
  PendingUserStatus,
  RegisteredUser,
  RegisteredUserStatus,
  RegularUserRoleEnum,
  ReportType,
  ResourceUserRoleEnum,
  SalesRepUserRoleEnum,
  UserRole,
  UserStatus,
} from '__generated-api__';
import api from 'api';
import { useAbility, useAuthenticatedUser } from 'auth';
import { Can } from 'auth/components/Can';
import { AutoSubmit } from 'components/Form/AutoSubmit';
import Form from 'components/Form/Form';
import SelectField from 'components/Form/SelectField';
import Icon from 'components/icon';
import { useMutation } from 'hooks/query';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { DataTableHero } from 'my-account/components/DataTable/Hero';
import { DataTableClearFilterForm, DataTableListing } from 'my-account/components/DataTable/Listing';
import { MainHero } from 'my-account/components/MainHero';
import { SelectAgency } from 'my-account/components/SelectAgency';
import { SelectCompany } from 'my-account/components/SelectCompany';
import { SelectLocation } from 'my-account/components/SelectLocation';
import { useToast } from 'my-account/toast';
import { UserRoleLabels } from 'my-account/utils/roles';
import { setLocationSearchParams } from 'utils/router';

const userRoleZodEnum = z.nativeEnum(UserRole).or(z.literal(''));
const usersFiltersSchema = z.object({
  agency: z
    .object({
      id: z.number().positive(),
      name: z.string().nullable(),
    })
    .nullable()
    .optional(),
  company: z
    .object({
      id: z.number().positive(),
      name: z.string().nullable(),
    })
    .nullable()
    .optional(),
  location: z
    .object({
      id: z.number().positive(),
      name: z.string().nullable(),
    })
    .nullable()
    .optional(),
  role: userRoleZodEnum,
  status: z
    .nativeEnum(RegisteredUserStatus)
    .or(z.nativeEnum(PendingUserStatus))
    .or(z.literal(''))
    .optional()
    .default(''),
});

const LocationFilter: React.VFC = () => {
  const { watch } = useFormContext<z.infer<typeof usersFiltersSchema>>();
  const company = watch('company');

  return (
    <SelectLocation
      elStyle="fill"
      name="location"
      label="Location"
      queryParams={company ? { companyId: company.id } : undefined}
      selectProps={{ autoComplete: 'off', isClearable: true }}
      small
    />
  );
};

const UsersFilters: React.VFC = () => {
  const user = useAuthenticatedUser();
  const history = useHistory();
  const location = useLocation();
  const searchParams = React.useMemo(() => new URLSearchParams(location.search), [location.search]);

  const initialValueParse = usersFiltersSchema.safeParse({
    agency: searchParams.has('agency') ? { id: Number(searchParams.get('agency')!), name: 'Loading...' } : undefined,
    company: searchParams.has('company') ? { id: Number(searchParams.get('company')!), name: 'Loading...' } : undefined,
    location: searchParams.has('location')
      ? { id: Number(searchParams.get('location')!), name: 'Loading...' }
      : undefined,
    role: searchParams.get('role') ?? '',
    status: searchParams.get('status') ?? undefined,
  });

  return (
    <>
      <Form
        schema={usersFiltersSchema}
        initialValues={initialValueParse.success ? initialValueParse.data : {}}
        onSubmit={async (values) => {
          history.replace(
            setLocationSearchParams(location, {
              agency: values.agency ? String(values.agency.id) : undefined,
              company: values.company ? String(values.company.id) : undefined,
              location: values.location ? String(values.location.id) : undefined,
              role: values.role ? values.role : undefined,
              status: values.status ? values.status : undefined,
              page: undefined,
              'list-page': undefined,
            })
          );
        }}
        isFiltersForm
      >
        <DataTableClearFilterForm />
        <AutoSubmit />
        <SelectField
          name="role"
          label="User Role"
          elStyle="fill"
          selectProps={{ autoComplete: 'off' }}
          allowEmpty
          emptyOptionLabel="Any User Role"
          small
        >
          {Object.entries(UserRoleLabels).map(([value, label]) => (
            <Can
              key={value}
              I="read"
              this={subject('User', {
                company_id: user.company_id,
                location_id: user.location_id ? user.location_id : undefined,
                role: value as RegisteredUser['role'],
                status: RegisteredUserStatus.Active,
              })}
            >
              <option value={value}>{label}</option>
            </Can>
          ))}
        </SelectField>
        <Can
          I="read"
          this={subject('User', {
            company_id: 0,
            role: RegularUserRoleEnum.User,
            status: RegisteredUserStatus.Active,
          })}
        >
          <SelectCompany
            elStyle="fill"
            name="company"
            label="Company"
            selectProps={{ autoComplete: 'off', isClearable: true }}
            small
          />
        </Can>
        <Can
          I="read"
          this={subject('User', {
            company_id: user.company_id,
            location_id: 0,
            role: RegularUserRoleEnum.User,
            status: RegisteredUserStatus.Active,
          })}
        >
          <LocationFilter />
        </Can>
        <Can
          I="read"
          this={subject('User', {
            agency_id: 0,
            role: RegularUserRoleEnum.User,
            status: RegisteredUserStatus.Active,
          })}
        >
          <SelectAgency
            elStyle="fill"
            name="agency"
            label="Agency"
            selectProps={{ autoComplete: 'off', isClearable: true }}
            small
          />
        </Can>
        <Can
          I="read"
          this={subject('User', {
            company_id: user.company_id,
            location_id: user.location_id ? user.location_id : undefined,
            role: RegularUserRoleEnum.User,
            status: RegisteredUserStatus.Active,
          })}
        >
          <SelectField name="status" label="Status" elStyle="fill" small>
            <option value={RegisteredUserStatus.Active}>Active</option>
            <option value={RegisteredUserStatus.Inactive}>Inactive</option>
            <option value={PendingUserStatus.Pending}>Pending</option>
          </SelectField>
        </Can>
      </Form>
    </>
  );
};

const LastLogin: React.VFC<{ date: string | null }> = ({ date }) => {
  if (!date) {
    return <p>Never</p>;
  }

  const d = parseISO(date);
  const diff = differenceInCalendarDays(new Date(), d);

  if (diff === 0) {
    return <p>Today</p>;
  }

  if (diff === 1) {
    return <p>Yesterday</p>;
  }

  return <p>{format(d, 'MMM d')}</p>;
};

export default function UsersPage() {
  useDocumentTitle('Users');
  const ability = useAbility();
  const toast = useToast();
  const queryClient = useQueryClient();
  const [deleteUser] = useMutation(api.user.deleteUser);
  const [updateUser] = useMutation(api.user.updateUser);

  const canCreateUser = ability.can('create', 'User');

  return (
    <>
      <MainHero />

      <DataTableHero
        title="Users"
        buttons={
          canCreateUser ? (
            <>
              <Link to="/users/add" className="c-button c-button--primary c-button--small u-my-spacer-base-small">
                <Icon name="add" className="o-svg-icon o-svg-right" />
                <span>Add new user</span>
              </Link>
            </>
          ) : undefined
        }
      />

      <section className="c-block c-block--spacing-b-extra-small c-block--spacing-b@md">
        <div className="o-container-fluid">
          <DataTableListing
            label="users"
            availableSortOptions={{
              updated_at: 'Recently Updated',
              last_name: 'Last Name',
              first_name: 'First Name',
              email: 'Email',
            }}
            defaultSort="updated_at"
            queryFn={api.user.listUsers}
            queryFnParams={(filters, searchParams) => {
              let agencyId: number | undefined = Number(searchParams.get('agency'));
              if (Number.isNaN(agencyId) || agencyId < 1) {
                agencyId = undefined;
              }

              let companyId: number | undefined = Number(searchParams.get('company'));
              if (Number.isNaN(companyId) || companyId < 1) {
                companyId = undefined;
              }

              let locationId: number | undefined = Number(searchParams.get('location'));
              if (Number.isNaN(locationId) || locationId < 1) {
                locationId = undefined;
              }

              let role: z.infer<typeof userRoleZodEnum> | undefined;
              if (searchParams.has('role')) {
                const roleValidation = userRoleZodEnum.safeParse(searchParams.get('role')!);

                if (roleValidation.success) {
                  role = roleValidation.data;
                }
              }

              const status: UserStatus | undefined =
                searchParams.get('status') === RegisteredUserStatus.Active
                  ? RegisteredUserStatus.Active
                  : searchParams.get('status') === RegisteredUserStatus.Inactive
                  ? RegisteredUserStatus.Inactive
                  : searchParams.get('status') === PendingUserStatus.Pending
                  ? PendingUserStatus.Pending
                  : undefined;

              return { ...filters, agencyId, companyId, locationId, role: role ? role : undefined, status };
            }}
            filters={() => <UsersFilters />}
            reportType={ReportType.User}
          >
            {(data) => (
              <>
                {data.map((user) => (
                  <Link key={user.id} to={`/users/${user.id}`} className="c-data-card">
                    <div className="c-data-card__column c-data-card__column--first">
                      <p className="c-data-card__subtitle">{UserRoleLabels[user.role]}</p>
                      <p className="c-data-card__title">
                        {[user.first_name, user.last_name].filter(Boolean).join(' ')}
                      </p>
                      <p className="c-data-card__label">User Name</p>
                    </div>

                    {user.role !== AdminUserRoleEnum.Admin &&
                    user.role !== CompanyManagerUserRoleEnum.CompanyManager &&
                    user.role !== SalesRepUserRoleEnum.SalesRep &&
                    user.role !== ResourceUserRoleEnum.ResourceAccess &&
                    user.role !== AgencyRepUserRoleEnum.AgencyRep ? (
                      <div className="c-data-card__column">
                        <p>{user.location ? user.location.name : 'None'}</p>
                        <p className="c-data-card__label">Location</p>
                      </div>
                    ) : (
                      <div className="c-data-card__column"></div>
                    )}

                    <div className="c-data-card__column">
                      <LastLogin date={user.last_login} />
                      <p className="c-data-card__label">Last Login</p>
                    </div>

                    <div className="c-data-card__column c-data-card__column--last">
                      <button className="c-link-cta-basic c-link-cta--small" type="button">
                        <span>User Details</span>
                        <Icon name="arrow" className="o-svg-icon o-svg-right" />
                      </button>
                    </div>

                    <Can I="delete" this={subject('User', user)}>
                      {!(
                        (user.role === CompanyManagerUserRoleEnum.CompanyManager ||
                          user.role === LocationManagerUserRoleEnum.LocationManager ||
                          user.role === RegularUserRoleEnum.User) &&
                        user.company &&
                        !user.company.status
                      ) && (
                        <div className="c-data-card-toolbar">
                          {(user.status === RegisteredUserStatus.Active ||
                            user.status === PendingUserStatus.Pending) && (
                            <button
                              onClick={async (event) => {
                                event.preventDefault();
                                const userName = [user.first_name, user.last_name].filter(Boolean).join(' ');
                                if (
                                  window.confirm(`Are you sure that you really want to archive "${userName}" user.`)
                                ) {
                                  await deleteUser([{ userId: user.id }]);
                                  toast.notify({
                                    type: 'success',
                                    title: 'Success',
                                    message: `The "${userName}" user was archived successfully.`,
                                  });
                                  await queryClient.cancelQueries(api.user.listUsers.getQueryKey()[0]);
                                  await queryClient.invalidateQueries(api.user.listUsers.getQueryKey()[0]);
                                }
                              }}
                              type="button"
                            >
                              <span className="c-data-card-toolbar__label">Archive User</span>
                              <Icon name="trash" className="c-data-card-toolbar__icon" />
                            </button>
                          )}

                          {(user.status === RegisteredUserStatus.Inactive ||
                            user.status === PendingUserStatus.Pending) && (
                            <button
                              onClick={async (event) => {
                                event.preventDefault();
                                const userName = [user.first_name, user.last_name].filter(Boolean).join(' ');
                                if (
                                  window.confirm(`Are you sure that you really want to activate "${userName}" user.`)
                                ) {
                                  await updateUser([
                                    {
                                      userId: user.id,
                                      userUpdate: { status: RegisteredUserStatus.Active },
                                    },
                                  ]);
                                  toast.notify({
                                    type: 'success',
                                    title: 'Success',
                                    message: `The "${userName}" user was activated successfully.`,
                                  });
                                  await queryClient.cancelQueries(api.user.listUsers.getQueryKey()[0]);
                                  await queryClient.invalidateQueries(api.user.listUsers.getQueryKey()[0]);
                                }
                              }}
                              type="button"
                            >
                              <span className="c-data-card-toolbar__label">Activate User</span>
                              <Icon name="export" className="c-data-card-toolbar__icon" />
                            </button>
                          )}
                        </div>
                      )}
                    </Can>
                  </Link>
                ))}
              </>
            )}
          </DataTableListing>
        </div>
      </section>
    </>
  );
}
