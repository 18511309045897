import { Link } from 'react-router-dom';
import { z } from 'zod';

import api from 'api';
import Form from 'components/Form/Form';
import InputField from 'components/Form/InputField';
import SubmitButton from 'components/Form/SubmitButton';
import Icon from 'components/icon';
import { useMutation } from 'hooks/query';
import useDocumentTitle from 'hooks/useDocumentTitle';

const RequestAccountSchema = z.object({
  first_name: z.string().min(1),
  last_name: z.string().min(1),
  email: z.string().email().min(1),
});

export function RequestAccountForm() {
  const [register, { isLoading, isSuccess }] = useMutation(api.user.registerResourceAccess);

  if (isSuccess) {
    return (
      <>
        <h6>Account Created!</h6>
        <p>
          We've received your request and we've sent you an email with your login details! Please click the link in that
          email to verify your account. (Be sure to check your junk/spam folder just in case!)
        </p>
      </>
    );
  }

  return (
    <>
      <h6>Contact Information</h6>
      <Form
        schema={RequestAccountSchema}
        initialValues={{ first_name: '', last_name: '', email: '' }}
        onSubmit={async (data) => {
          await register([{ requestResourceAccountBody: { ...data } }]);
        }}
        className="u-mb-spacer-section-small"
        hasFloatingLabels
      >
        <div className="o-row o-row--small-gutters">
          <div className="o-col-6@sm">
            <InputField
              name="first_name"
              type="text"
              label="First Name"
              disabled={isLoading}
              elStyle="fill"
              inputProps={{
                autoComplete: 'given-name',
              }}
            />
          </div>
          <div className="o-col-6@sm">
            <InputField
              name="last_name"
              type="text"
              label="Last Name"
              disabled={isLoading}
              elStyle="fill"
              inputProps={{
                autoComplete: 'family-name',
              }}
            />
          </div>
          <div className="o-col-12@sm">
            <InputField
              name="email"
              type="email"
              label="Email address"
              disabled={isLoading}
              elStyle="fill"
              inputProps={{
                autoComplete: 'email',
              }}
            />
          </div>
          <div className="o-col-12@sm">
            <div className="c-form-footer">
              <SubmitButton variant="secondary" isLoading={isLoading} isFull>
                Sign up
              </SubmitButton>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
}

export default function RequestResourceAccessPage() {
  useDocumentTitle('Request Resource Access');
  return (
    <>
      <section className="c-block c-block--bg-light2 c-block--hero c-block--height-xs@xs">
        <div className="o-container-fluid">
          <div className="o-row u-flex-grow u-items-center">
            <div className="o-col-12@md u-justify-around">
              <div className="c-block__header u-items-center u-mt-spacer-base">
                <h1 className="c-title--extra-large">Need Access?</h1>
                <div className="c-hero__desc">
                  <p>Just enter your details and we’ll set you up with access to the resources you need!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="c-block__pattern c-block__pattern--light3 c-block__overlay--opacity-40">
          <Icon name="pattern" className="o-svg-icon" />
        </div>
      </section>

      <section className="c-block c-block--spacing-t-small c-block--spacing-t@md c-block--spacing-b-small c-block--spacing-b@md">
        <div className="o-container-fluid">
          <div className="o-row">
            <div className="o-col-8@md o-col-6@lg u-mx-auto">
              <RequestAccountForm />
              <div className="c-info c-info--sm u-mb-spacer-base u-mx-auto">
                <div className="c-info__header">
                  <div className="c-info__icon u-text-secondary">
                    <Icon name="info" className="o-svg-icon" />
                  </div>
                  <p>
                    Already have an account?{' '}
                    <Link
                      to="/auth/login"
                      className="c-link-cta-basic c-link-cta--small u-mb-0 u-ml-spacer-base-small@sm"
                    >
                      <span>Login</span>
                      <Icon name="arrow" className="o-svg-icon o-svg-right" />
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
