import classNames from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';

import {
  AdminUserRoleEnum,
  AgencyRepUserRoleEnum,
  ResourceUserRoleEnum,
  SalesRepUserRoleEnum,
} from '__generated-api__';
import { AuthStatus, useAuth } from 'auth';
import Icon from 'components/icon';
import { ProfileImage } from 'my-account/components/ProfileImage';
import { UserRoleLabels } from 'my-account/utils/roles';

export const SidebarProfilePreview: React.VFC<{ className?: string }> = ({ className }) => {
  const auth = useAuth();

  if (auth.status !== AuthStatus.LoggedIn && auth.status !== AuthStatus.LoggingOut) {
    return null;
  }

  const user = auth.currentUser;
  const name = [user.first_name, user.last_name].filter(Boolean).join(' ');

  return (
    <div className={classNames('c-profile-preview', className)}>
      <div className="c-profile-preview__main">
        <ProfileImage user={user} />

        <div className="c-profile-preview__content">
          <p className="c-profile-preview__title">{name}</p>
          <p className="c-profile-preview__subtitle">{UserRoleLabels[user.role]}</p>
          {user.role !== AdminUserRoleEnum.Admin &&
            user.role !== SalesRepUserRoleEnum.SalesRep &&
            user.role !== AgencyRepUserRoleEnum.AgencyRep &&
            user.role !== ResourceUserRoleEnum.ResourceAccess && (
              <Link to={`/companies/${user.company.id}`} className="c-tag c-tag--stroke">
                {user.company.name}
              </Link>
            )}
        </div>
      </div>

      <div className="c-profile-preview__footer">
        <ul className="c-divider-list u-mb-0">
          <li className="c-divider-list__item">
            <Link to="/profile#change-password" className="c-link-cta-basic">
              <Icon name="keyhole" className="o-svg-icon o-svg-larger" />
              <span>Change password</span>
            </Link>
          </li>
          <li className="c-divider-list__item">
            <Link to="/profile" className="c-link-cta">
              <span>Edit profile</span>
              <Icon name="arrow" className="o-svg-icon o-svg-right" />
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};
