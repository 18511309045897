import { useHistory, useLocation } from 'react-router';
import { useMount } from 'react-use';
import { z } from 'zod';

import api from 'api';
import { AuthStatus, useAuth, useAuthenticatedUser } from 'auth';
import Form from 'components/Form/Form';
import InputField from 'components/Form/InputField';
import SubmitButton from 'components/Form/SubmitButton';
import Icon from 'components/icon';
import { useMutation } from 'hooks/query';
import useDocumentTitle from 'hooks/useDocumentTitle';
import ProductImage from 'my-account/images/product-01.jpg';
import { useToast } from 'my-account/toast';
import { useErrorHandler } from 'my-account/utils/error-handler';

const ChangePasswordSchema = z
  .object({
    password: z.string().nonempty(),
    password_confirmation: z.string(),
  })
  .refine((data) => data.password === data.password_confirmation, {
    message: "Passwords don't match",
    path: ['password_confirmation'],
  });

const ResetPasswordForm = () => {
  const history = useHistory();
  const toast = useToast();
  const user = useAuthenticatedUser();
  const [updateProfile, updateProfileState] = useMutation(api.user.updateProfile);

  return (
    <Form
      schema={ChangePasswordSchema}
      initialValues={{ password: '', password_confirmation: '' }}
      onSubmit={async (data) => {
        await updateProfile([
          {
            userUpdateUserUpdatePasswordUpdate: {
              first_name: user.first_name ?? '',
              last_name: user.last_name ?? '',
              email: user.email,
              password: data.password,
              password_confirmation: data.password_confirmation,
            },
          },
        ]);

        toast.notify({
          type: 'success',
          title: 'Success',
          message: 'Your password was successfully set.',
        });

        history.replace('/');
      }}
      className="c-form-group c-form-group--sm c-form-group--ver u-ml-auto u-mr-auto"
      hasFloatingLabels
    >
      <div className="o-row o-row--no-gutters c-form-group__dividers u-flex-grow">
        <div className="o-col-12">
          <InputField
            name="password"
            type="password"
            label="Password"
            icon={<Icon name="keyhole" className="o-svg-icon" />}
            className="c-form-element--high c-form-element--style-line"
            disabled={updateProfileState.isLoading}
            inputProps={{
              autoComplete: 'new-password',
            }}
          />
        </div>
        <div className="o-col-12">
          <InputField
            name="password_confirmation"
            type="password"
            label="Repeat Password"
            icon={<Icon name="keyhole" className="o-svg-icon" />}
            className="c-form-element--high c-form-element--style-line"
            disabled={updateProfileState.isLoading}
            inputProps={{
              autoComplete: 'new-password',
            }}
          />
        </div>
      </div>
      <div className="c-form-footer">
        <SubmitButton isLoading={updateProfileState.isLoading} isFull>
          Set Password
        </SubmitButton>
      </div>
    </Form>
  );
};

export default function VerifyWelcomePage() {
  useDocumentTitle('Set Password');
  const toast = useToast();
  const auth = useAuth();
  const history = useHistory();
  const location = useLocation();
  const { clearErrors, handleError } = useErrorHandler();

  useMount(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const email = searchParams.get('email');

    clearErrors();

    if (!token || !email) {
      toast.notify({
        type: 'error',
        title: 'Error',
        message: 'Invalid request.',
        autoClose: false,
      });

      history.replace('/auth/login');

      return;
    }

    auth.verifyReset(token, email).catch((error) => {
      handleError(error);

      history.replace('/auth/forgot-password');

      throw error;
    });
  });

  return (
    <section
      className="c-block c-block--spacing-t-extra-small c-block--spacing-t-small@md c-block--spacing-b-extra-small c-block--spacing-b-small@md c-block--bg-light2 c-block--hero c-block--height-semifull@xs c-block--bg-image"
      style={{ backgroundImage: `url(${ProductImage})` }}
    >
      <div className="o-container-fluid">
        <div className="o-row u-flex-grow u-items-center">
          <div className="o-col-12">
            <div className="c-block__header c-block__header--xl c-block__header--large u-items-center u-text-center">
              <h1 className="c-title--extra-large">Welcome to Symmons!</h1>
              <div className="c-hero__desc">
                <p>Please enter a password below. We recommend using a strong password and a password manager.</p>
              </div>
            </div>
            {auth.loginMutationState.isSuccess && auth.status === AuthStatus.LoggedIn ? (
              <ResetPasswordForm />
            ) : auth.loginMutationState.isError ? null : (
              <div className="c-listing__none">
                <div className="c-listing__none-spinner"></div>
                <p className="c-listing__none-title">Loading...</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="c-block__pattern c-block__pattern--light3 c-block__overlay--opacity-40">
        <Icon name="pattern" className="o-svg-icon" />
      </div>
      <div className="c-block__overlay c-block__overlay--light2 c-block__overlay--opacity-85"></div>
    </section>
  );
}
